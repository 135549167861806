html, body, #root{
  height: 100%;
  scroll-behavior: smooth;
  background: #f2f6fc;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: 'PTSansBold';
  src: local('MyFont'), url(./fonts/PT_Sans-Web-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'PTSansRegular';
  src: local('MyFont'), url(./fonts/PT_Sans-Web-Regular.ttf) format('truetype');
}

